import React, { useState } from 'react'
import Header from '../landingpage/components/Header'
import Footer, { FooterSocial } from '../landingpage/components/Footer'
import keyIcon from "../../assets/images/illustration.png"
// import  {OTPInput} from 'input-otp'
import {OtpInput} from "reactjs-otp-input"
import { toast } from 'react-toastify'
import SuccessAlert from '../../components/SuccessAlert'
import { useValidateOTP } from '../../hooks/landingpage/useLandingPage';
import { useNavigate, useSearchParams } from 'react-router-dom'
import Swal from 'sweetalert2'
function VerifyEmail() {
const [searchParams]= useSearchParams()
const navigate= useNavigate()

  const [otp, setOtp] = useState(searchParams.get("otp")||"");
const email= searchParams.get('email')||""
 const handleToast=()=> toast(<SuccessAlert/>, {
  position:"top-center",
  hideProgressBar:true,
  closeButton:true,
  toastId:"imageToast",
  className:"w-[400px] bg-transparent",
  bodyClassName:"bg-transparent",
  theme:"dark",
  style:{background:"transparent", padding:"10px"},
onClose(){
  
navigate("/tap?action=signup")
}
 })

 const validateAPI= useValidateOTP()
 const verifyEmail= ()=>{
  validateAPI.mutate({
    email,
    otp
  }, {
    onSuccess(data:any){
      console.log({data})
      if(data.message==="You have successfully validated your email address."){
        sessionStorage.setItem("email_verified_at",data?.data?.email_verified_at || "")
        sessionStorage.setItem("email",data?.data?.user?.email || "")
        sessionStorage.setItem("first_name",data?.data?.user?.first_name || "")
        sessionStorage.setItem("last_name",data?.data?.user?.last_name || "")
        sessionStorage.setItem("phone_number",data?.data?.user?.phone_number || "")
        // handleToast()
        Swal.fire({
          title: " Email verified!",
          text: "Please complete the sign up form to join the TAP program.",
          icon: "success",
           confirmButtonColor:"#8CAF4B",
           confirmButtonText:"Proceed to sign up "
        
          }).finally(()=>{
            navigate("/tap?action=signup")
          })

      }
    },
    onError(err:any){
      console.log({err})
    }
  })
 }
  return (
    <div className=' text-white bg-primary'>
      <Header theme='light' />
     
      <div className="flex justify-center">
        
      <div className="rounded-2xl bg-black max-w-2xl flex flex-col justify-center items-center p-4 px-6" >
      <div className="my-4">
        <img src={keyIcon} alt="" />
      </div>
      <h4 className="text-white font-bold text-4xl mb-3">Verify your email</h4>
      <p className="text-white max-w-lg">
      Please click the button to verify your email address to join the TAP program.</p>
    



      <OtpInput

      value={otp}
      onChange={setOtp}
      numInputs={6}
      separator={<span className='mx-2'> </span>}
      inputStyle={`h-10 min-w-10 border border-primary bg-[#8CAF4B] !text-white mb-4 rounded-lg`}
      // render={(props:any) => <input {...props} className='h-10 min-w-10 border border-primary bg-white mb-4 rounded-lg' />}
      // render={({ slots }) => (
      //   <>
      //     <div className="flex">
      //       {slots.slice(0, 3).map((slot, idx) => (
      //         <Slot key={idx} {...slot} />
      //       ))}
      //     </div>
    
      //     <FakeDash />
    
      //     <div className="flex">
      //       {slots.slice(3).map((slot, idx) => (
      //         <Slot key={idx} {...slot} />
      //       ))}
      //     </div>
      //   </>
      // )}
    />

<button
disabled={validateAPI.isLoading}
 onClick={verifyEmail}
 className="btn btn-block rounded-full bg-primary text-white mb-6">Verify Email</button>

<div className="flex items-center">
  <p className="text-white">Didnt Receive Code?</p>
  <button className="text-primary ml-2">Resend Code</button>
</div>
      </div>



      </div>
   

      <div className="bg-black mt-6 flex flex-col md:flex-row items-center justify-between p-8 ">
 
  <ul className="flex flex-col md:flex-row justify-center lg:justify-start  items-center w-full  gap-4 md:gap-4">
    <li className=''>
      <p>Support</p>
    </li>
    <li>
      <p>Privacy Policy</p>
    </li>
    <li>
      <p>Terms and Condition</p>
    </li>
  </ul>
  <p className="text-center md:text-right w-full">© 2020 TAP All Rights Reserved</p>
</div>
    </div>
  )
}

export default VerifyEmail
