import React from 'react'

import TALogo from '../../../assets/images/ta-logo.png'
import TAWhiteLogo from '../../../assets/images/ta-white-logo.png'
import { Link } from 'react-router-dom'
interface Header{
  theme?:"light"|"dark",

}
function Header(props:Header) {
  const {theme}=props
  return (
    <div
    // className='bg-gradient-to-r from-[rgba(140, 175, 75, 0.27)] to-blue'  
    // style={{background:" linear-gradient(to right, rgba(140, 175, 75, 0.27) 0%, rgba(140, 175, 75, 0.10)  100%)" }}
   className=""
   >
      <div className="flex flex-col md:flex-row items-center justify-between p-8 pl-2 md:pl-8">
        <div className="flex w-full   md:justify-start md:pl-8">
          <img src={theme==="light"?TAWhiteLogo:TALogo} alt="" className='w-fit' />
        </div>
        <ul className=" hidden md:flex flex-col md:flex-row justify-center  items-center w-full p-4 gap-4 md:gap-6">
         
          <li className=''>
        <Link to="/tap">
            <p  className={`${theme==='light'?'text-white':"text-dark"}`}>Home</p>
          </Link>
          </li>
          <li>
          <Link to="/about-us">
          
            <p className={`${theme==='light'?'text-white':"text-dark"}`}>About Us</p>
          </Link>
          </li>
        
          <Link to="/job-listings">
          <li>
            <p className={`${theme==='light'?'text-white':"text-dark"}`}>Find Jobs</p>
          </li>

          </Link>

          <Link to="/for-companies">
          <li>
            <p className={`${theme==='light'?'text-white':"text-dark"}`}>For Companies</p>
          </li>
          </Link>
        </ul>
        <div className="w-full hidden md:flex justify-end">
          {/* <button className={`border ${theme==="light"?"border-white":"border-primary"} w-48 h-8 rounded-full`}></button> */}
        </div>
      </div>
    </div>
  )
}

export default Header
