import { Field, Form, Formik } from 'formik'
import React from 'react'
import { IRegisterPayload, useRegistration } from '../../../hooks/landingpage/useLandingPage'
import { toast } from 'react-toastify'
import Swal from 'sweetalert2'



interface NewsletterPayload{
    formDetails:IRegisterPayload, setFormDetails:any
}
function Newsletter({formDetails, setFormDetails}:NewsletterPayload) {
    const registerAPI= useRegistration() 
const first_name= sessionStorage.getItem("first_name") || ""
const last_name= sessionStorage.getItem("last_name") || ""
const email= sessionStorage.getItem("email") || ""
const email_verified_at= sessionStorage.getItem("email_verified_at") || ""
const phone_number= sessionStorage.getItem("phone_number") || ""
console.log({...formDetails})
  return (
   <section className='p-6  flex flex-col items-center'>
<div className="flex flex-col md:flex-row mb-8 items-center gap-4 lg:gap-8">
    <h3 className="md:flex-0.5 mb-4 text-4xl max-w-lg font-semibold text-black dark:text-white"> Sign Up Now for the TAP program!</h3>
    <p className='text-lg w-full max-w-lg md:flex-0.5 text-black dark:text-white'>Unlock your potential with test preparations, tailored courses, expert-led webinars, and job fairs and a thriving community. Take the first step toward landing your dream job!</p>
</div>

<div className="w-full max-w-2xl bg-[#8CAF4B] dark:bg-white rounded-2xl p-4 md:p-8 text-black">
    <Formik initialValues={{
...formDetails, registration:"webinar"
}}

enableReinitialize
    onSubmit={(values, {resetForm,} )=>{
registerAPI.mutate(({...values as IRegisterPayload}), {
    onSuccess(data){
console.log({data})
resetForm()
setFormDetails({
    email:"",first_name:"", last_name:"", phone_number:"", registration:"webinar", email_verified_at:""
})
sessionStorage.clear()
toast.dismiss()
// toast.success(data?.message)
Swal.fire({
    title: "Thank you for joining the TAP program..",
    text: "You will have access to test preparation, tailored courses, expert-led webinars and job fairs. We look forward to supporting you on your journey to success",
    icon: "success",
    confirmButtonColor:"#8CAF4B",
    confirmButtonText:"close"
  });
    },
    onError(err:any){
    toast.dismiss()
toast.error(err?.response?.data?.message)
    }
})
    }}>
{
    ({isSubmitting, values})=><Form>
        <p className='font-semibold mb-2 text-3xl text-black'> Sign Up </p>
        <p className='text-black'>Fill your personal details to continue the sign up</p>

        <div className=" flex justify-between gap-4 mt-8">
            <div className="w-full">
                <p  className='text-sm text-black font-semibold mb-1'>First Name <span className='text-red-500'>*</span></p>
                <Field disabled={!values.email} id="first_name" name="first_name" required className="w-full input input-bordered dark:bg-white md:input-sm "/>
            </div>
            <div className="w-full">
                <p className='text-sm text-black font-semibold mb-1'>Last Name <span className='text-red-500'>*</span></p>
                <Field name="last_name" disabled={!values.email} required className="w-full input input-bordered md:input-sm dark:bg-white"/>
            </div>
        </div>

        <div className="w-full mt-4">
                <p className='text-sm text-black font-semibold  mb-1'>Email <span className='text-red-500'>*</span></p>
                <Field name="email" required disabled className="w-full input input-bordered md:input-sm dark:bg-white dark:text-black"/>
            </div>
        <div className="w-full mt-4">
                <p className='text-sm text-black font-semibold  mb-1'>Phone Number <span className='text-red-500'>*</span></p>
                <Field name="phone_number" disabled={!values.email} placeholder="+2348012345678" required className="w-full input input-bordered md:input-sm dark:bg-white"/>
            </div>
        <div className="w-full mt-4">
                <p className='text-sm text-black font-semibold  mb-1'>How did you hear about us?</p>
                <Field as="select" name="referral" disabled={!values.email} className="w-full select select-bordered md:input-sm dark:bg-white">
<option value="" disabled>Select</option>
<option value="Google Search">Google Search</option>
<option value="Linkedin">Linkedin</option>
<option value="Instagram">Instagram</option>
<option value="Facebook">Facebook</option>
<option value="Twitter">Twitter</option>
<option value="Email Newsletter">Email Newsletter</option>
<option value="Sms">Sms</option>
<option value="Word of Mouth / Referred by a friend">Word of Mouth / Referred by a friend</option>
<option value="Online Ad">Online Ad</option>
                    </Field>
            </div>
        {/* <div className="w-full mt-4">
                <p className='text-sm text-black font-semibold  mb-1'>Linkedin Url</p>
                <Field name="social_url" className="w-full input input-bordered md:input-sm dark:bg-white"/>
            </div> */}

        <div className="">
            <button disabled={isSubmitting || !values.email} className="btn btn-lg px-16  md:btn-sm mt-8 dark:bg-[#8CAF4B] dark:text-white">Submit</button>
        </div>
    </Form>
}
    </Formik>
</div>

   </section>
  )
}

export default Newsletter
